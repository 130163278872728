import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { FaChevronRight } from "react-icons/fa"

// Components
import SEO from "../components/seo"
import Layout from "../components/layout"
import SectionTitle from "../components/SectionTitle"
import BackgroundImage2 from "../components/BackgroundImage"
import BlockContentPrimary from "../components/blockContentPrimary"
import Syllabics from "../components/Syllabics"
import DisplayPosts from "../components/DisplayPosts"
import ButtonTemplate from "../components/buttons/ButtonTemplate"

const CommunityPageTemplate = ({ data }) => {
  const blogPosts = data.allSanityBlogPost.edges
  const {
    communityDescription,
    communityName,
    communityLogo,
    syllabicsTitle,
    heroImage,
    _rawMainContent,
    href,
  } = data.sanityIndividualCommunityPages

  const logo = communityLogo
    ? communityLogo.asset.fluid
    : data.ShibLogo.childImageSharp.fluid

  // If a hero image is provided in Sanity CMS, use it, else use the default image
  const backgroundImage = heroImage ? heroImage.asset : null

  const [postCount, setpostCount] = useState(3)

  let displayBlogPosts = blogPosts.slice(0, postCount)

  const increasePostCount = () => {
    return setpostCount(postCount + 5)
  }

  return (
    <Layout>
      <SEO title={communityName} />
      {/* Below Mimics the <Hero> component, but has a few different needs */}
      <div className="flex items-center  bg-top bg-cover overflow-hidden">
        <BackgroundImage2 heroImage={backgroundImage}>
          <div className="flex-col mx-auto md:mt-12 items-center ">
            <Img
              className=" mx-auto mt-10"
              fluid={logo}
              style={{ maxWidth: "400px" }}
            />
            {/* <pre className="text-white w-1/3">{JSON.stringify(fluid, null, 2)}</pre> */}
            <div className="flex flex-col my-4 ml-2 sm:ml-0 text-center">
              <h1 className="font-heading tracking-tight p-4 mb-2 text-2xl md:text-4xl bg-white bg-opacity-75 text-black">
                {communityName}
              </h1>
              {syllabicsTitle && (
                <h1 className="font-heading text-2xl md:text-4xl p-4 my-1 bg-white bg-opacity-75 text-blue-light mb-16">
                  <Syllabics className="text-blue-light">
                    {syllabicsTitle}
                  </Syllabics>
                </h1>
              )}
            </div>
          </div>
        </BackgroundImage2>
      </div>
      {communityDescription && (
        <section className="flex flex-col justify-center mt-10 md:-mt-12  bg-gray-dark max-w-4xl mx-auto">
          <h3 className="font-heading text-center mt-4 text-4xl">About</h3>
          <p className="text-justify text-md w-full px-16 pb-4 leading-tight">
            {communityDescription}
          </p>
        </section>
      )}
      {/* MAIN CONTENT */}

      <div className="grid">
        <div className="pt-12 justify-self-center ">
          {href && (
            <a
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="blockContent__link text-xl "
            >
              <h4 className="mb-3 text-center font-bold">{communityName}</h4>
            </a>
          )}
        </div>
        <div className="flex justify-center  w-4/5 md:w-3/4 mt-6 justify-self-center">
          <BlockContentPrimary blockData={_rawMainContent} />
        </div>
        {/* End of main content */}
        {/* <SectionTitle>{communityName}&nbsp;News</SectionTitle>
        <DisplayPosts posts={displayBlogPosts} /> */}

        <ButtonTemplate
          to="/communities"
          text="All Communities"
          className="text-white uppercase tracking-wide my-10 justify-self-center"
          index={1}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query communityPageQuery($slug: String!, $id: String!) {
    sanityIndividualCommunityPages(slug: { current: { eq: $slug } }) {
      communityName
      communityLogo {
        asset {
          assetId
          fluid {
            ...GatsbySanityImageFluid
          }
        }
        altText
      }
      id
      slug {
        current
      }
      href
      heroImage {
        asset {
          fluid(maxWidth: 1800) {
            ...GatsbySanityImageFluid
          }
        }
      }

      _rawMainContent(resolveReferences: { maxDepth: 5 })
      communityDescription
      syllabicsTitle
    }
    allSanityBlogPost(
      filter: {
        refs: { communityReference: { elemMatch: { id: { eq: $id } } } }
      }
      sort: { fields: _createdAt, order: DESC }
    ) {
      edges {
        node {
          ...DisplayPostsFragment
        }
      }
    }

    ShibLogo: file(relativePath: { eq: "Shibogama_Logo.png" }) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default CommunityPageTemplate
